import axios, { toQueryString } from "./axios";

const getMessages = (userId) => {
  return axios.get(`notification/user/${userId}`);
};

const getMessageById = (notificationId) => {
  return axios.get(`notification/${notificationId}`);
};

const changeMessageStatus = (notificationId) => {
  return axios.post(`notification/read/${notificationId}`);
};

const createMessage = (values) => {
  return axios.post("notification/create", values);
};

const getAllMessages = () => {
  return axios.get("notification");
};

const getFilterMessages = (values) => {
  const queryString = toQueryString(values);
  return axios.get(`notification/filter?${queryString}`);
}

const setUploadFile = (files,idNotification) =>{ 

let reader = new FileReader();
reader.readAsArrayBuffer(files);
reader.onload = function(){ 
  axios.defaults.timeout = 15000;
  return axios.post(`file/${idNotification}`, reader.result, {  
  
    headers: {
      'Content-Type': files.type
    }      
  }) 
};
}

export default {
  getMessages,
  getAllMessages,
  getMessageById,
  getFilterMessages,
  changeMessageStatus,
  createMessage,
  setUploadFile,
};
