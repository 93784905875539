/* eslint-disable no-unused-vars */
import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

import { TopBar } from "./components";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  topBar: {
    zIndex: 2,
    position: "relative",
    //background: "linear-gradient(45deg, #00ADCE 30%, #934291 90%)",
    background: "#00935f",
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "auto",
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto",
  },
}));

const Dashboard = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar className={classes.topBar} />
      <div className={classes.container}>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>{children}</Suspense>
        </main>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
