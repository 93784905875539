import axios from "./axios";

const login = (values) => {
  const { identityNumber: id_number, password } = values;
  return axios.post("/auth/login", { id_number, password });
};

export default {
  login,
};
