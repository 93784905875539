import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import AppRoute from "./routes/route";
import { publicRoutes, authProtectedRoutes } from "./routes";
import AuthLayout from "./layouts/Auth";
import Dashboard from "./layouts/Dashboard";

import theme from "./theme";
import store from "./store";

import "./assets/scss/index.scss";

const App = () => {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={AuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Dashboard}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Router>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
