import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
} from "store/types";

export const loginRequest = (values, history) => ({
  type: LOGIN_REQUEST,
  payload: { values, history },
});

export const loginSuccess = (values) => ({
  type: LOGIN_SUCCESS,
  payload: values,
});

export const loginFailed = (values) => ({
  type: LOGIN_FAILED,
  payload: values,
});

export const logout = () => ({
  type: LOGOUT,
});
