/* istanbul ignore file */
import axios from "axios";
import { getLoggedInUser } from "utils/authUtils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 80000,
});

const getUrl = (config) => {
  if (config?.baseURL) {
    return config.url.replace(config.baseURL, "");
  }
  return config?.url;
};

const toQueryString = (values) =>
  Object.keys(values)
    .map((key) => key + "=" + values[key])
    .join("&");

instance.interceptors.request.use(
  async (config) => {
    const currentUser = getLoggedInUser();
    if (currentUser) {
      const { access_token } = currentUser;
      config.headers.Authorization = `Bearer ${access_token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    response => {
        return response;
    }, error => {
        if (!window.location.pathname.includes('login') && error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
        }
    return Promise.reject(error);
  }
);

export { toQueryString, getUrl };

export default instance;
