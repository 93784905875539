/* istanbul ignore file */
import { call, put, takeEvery } from "redux-saga/effects";
import { setCredentials } from "utils/authUtils";

import * as authActions from "../actions/authActions";
import { LOGIN_REQUEST } from "../types";
import { authService } from "services";

function* login({ payload: { values, history } }) {
  try {
    const response = yield call(authService.login, values);
    const { data } = response;
    if(data.role === 'SUPERADMIN') {
      setCredentials(data);
      yield put(authActions.loginSuccess(data));
      history.push("/");
    } else {
      yield put(authActions.loginFailed({status: 401}));
    }
  } catch (error) {
    yield put(authActions.loginFailed(error.response));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_REQUEST, login);
}

export default authSaga;
