import axios from "./axios";

const getUserByIdentityNumber = (identityNumber) => {
  return axios.get(`user/${identityNumber}`);
};

const createUser = (values) => {
  return axios.post("user/create", values);
};

const updateUser = (id, values) => {
  return axios.put(`user/${id}`, values);
};

const getAllUsers = () => {
  return axios.get("user");
};

export default {
  getUserByIdentityNumber,
  createUser,
  updateUser,
  getAllUsers
};
