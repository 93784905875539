import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

const authProtectedRoutes = [
  {
    path: "/home",
    component: lazy(() => import("views/Home")),
  },
  {
    path: "/message-management",
    component: lazy(() => import("views/MessageManagementCreate")),
  },
  {
    path:"/message-list",
    component: lazy(() => import("views/MessageManagementList"))
  },
  {
    path: "/create-user",
    component: lazy(() => import("views/CreateUser")),
  },
  {
    path: "/edit-user/:id_number",
    component: lazy(() => import("views/CreateUser")),
  },
  {
    path: "/user-list",
    component: lazy(() => import("views/UserList")),
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />,
  },
];

const publicRoutes = [
  { path: "/login", component: lazy(() => import("views/Login")) },
];

export { authProtectedRoutes, publicRoutes };
