import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
} from "store/types";

import { getLoggedInUser } from "utils/authUtils";

const user = getLoggedInUser();

const initialState = user
  ? { isLoggedIn: true, user, loading: false, error: null }
  : { isLoggedIn: false, user: null, loading: false, error: null };

const auth = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return { ...state, loading: false, isLoggedIn: true, user: payload };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        loading: false,
        error: false,
        isLoggedIn: false,
        user: null,
      };
    default:
      return { ...state };
  }
};

export default auth;
